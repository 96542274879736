import { PaymentConfirmationAPIResponse } from 'api/merchant/dashboard/settings/payment.types';

import { NotificationEmailsModalForm } from '../../partials/notification-emails-modal/types';

type ToNotificationEmailsModalFormType = (
  paymentConfirmationAPIResponse: PaymentConfirmationAPIResponse,
) => NotificationEmailsModalForm;
type ToPaymentConfirmationAPIResponseType = (
  notificationEmailsModalForm: NotificationEmailsModalForm,
  paymentConfirmationAPIResponse: PaymentConfirmationAPIResponse,
) => PaymentConfirmationAPIResponse;

export const toNotificationEmailsModalForm: ToNotificationEmailsModalFormType = (
  paymentConfirmationAPIResponse: PaymentConfirmationAPIResponse,
) => ({
  enabled: paymentConfirmationAPIResponse.orderPaidNotificationsEnabled,
  emailList: paymentConfirmationAPIResponse.orderPaidNotificationEmails || [],
  enteredEmail: '',
});

export const toPaymentConfirmationAPIResponse: ToPaymentConfirmationAPIResponseType = (
  notificationEmailsModalForm: NotificationEmailsModalForm,
  paymentConfirmationAPIResponse: PaymentConfirmationAPIResponse,
) => ({
  ...paymentConfirmationAPIResponse,
  orderPaidNotificationsEnabled: notificationEmailsModalForm.enabled,
  orderPaidNotificationEmails: notificationEmailsModalForm.emailList,
});
