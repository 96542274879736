import {
  getInitialFilterState,
  getInitialListingPageState,
} from 'pages/merchant/dashboard/payout/listing/initialStates';
import { IListingPagePropsObj, IPayoutFilterObj } from 'pages/merchant/dashboard/payout/listing/types';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IConfig {
  payoutFilterObj: IPayoutFilterObj;
  listingPageProps: IListingPagePropsObj;
}

const initialState: IConfig = {
  payoutFilterObj: getInitialFilterState(),
  listingPageProps: getInitialListingPageState(),
};

export const payoutSlice = createSlice({
  name: 'payoutConfig',
  initialState,
  reducers: {
    setPayoutFilterObj: (state, action: PayloadAction<IPayoutFilterObj>) => {
      state.payoutFilterObj = action.payload;
    },
    setListingPagePropsObj: (state, action: PayloadAction<IListingPagePropsObj>) => {
      state.listingPageProps = action.payload;
    },
  },
});

export const { setPayoutFilterObj, setListingPagePropsObj } = payoutSlice.actions;

export default payoutSlice.reducer;
