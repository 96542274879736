/** @jsxImportSource @emotion/react */

import { searchTransactionsQueryDocument } from 'api/graphql';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks'; // TODO: merchantId prop
import { useQuery } from 'urql';

import { SearchFilter, SearchFilterOption, SearchFilterProps } from './SearchFilter';

export type TransactionFilterProps = {
  value: SearchFilterOption | null;
  onChange(value: SearchFilterOption | null): void;
};

export function TransactionFilter({ value, onChange, ...props }: TransactionFilterProps) {
  const [query, setQuery] = useState('');

  // TODO: merchantId prop
  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
  }));

  const pause = query.length < 3;

  const [{ fetching, data, error }] = useQuery({
    query: searchTransactionsQueryDocument,
    variables: { merchantIds: merchantId, query },
    pause,
  });

  const options = useMemo<SearchFilterProps['options']>(() => {
    if (pause || !data?.search.transactions) return [];

    return data.search.transactions.map(({ transactionId }) => ({
      value: transactionId,
      label: transactionId,
    }));
  }, [data?.search.transactions, pause]);

  return (
    <SearchFilter
      value={value}
      options={options}
      placeholder="Search transaction ID"
      loading={fetching}
      error={error?.message}
      onChange={onChange}
      onInputChange={setQuery}
    />
  );
}
