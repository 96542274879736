import { StandardMerchant } from './merchants.types';

export const getStandardMerchantInitValues = (): StandardMerchant => ({
  StandardMerchant: {
    merchantId: '',
    businessProfile: {
      timeZone: '',
      businessName: '',
      businessType: 'company',
      companyType: 'Private',
      website: '',
      supportSite: '',
      industry: '',
      taxId: '',
      taxCountry: 'AU',
      contactPerson: {
        title: '',
        givenName: '',
        familyName: '',
      },
      contactEmailAddress: '',
      supportEmailAddress: '',
      phoneNumber: '',
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'AU',
      },
      brandName: '',
      cardStatementName: '',
      smsSenderName: '',
    },
    branding: {
      iconUri: '',
      logoUri: '',
      logoAltText: '',
      primaryColor: '',
    },
    billingDetails: {
      billingNotificationsEnabled: false,
    },
    orderCustomFields: [],
  },
});
