import numeral from 'numeral';

export type Currency = 'AUD' | 'NZD' | 'ZAR';

export const CountryCurrency: { [country: string]: Currency } = {
  AU: 'AUD',
  NZ: 'NZD',
  ZA: 'ZAR',
};

export const CurrencyLocale = {
  AUD: 'en-AU',
  NZD: 'en-NZ',
  ZAR: 'en-ZA',
};

export const formatCurrency = (amount: number, currency: Currency): string =>
  new Intl.NumberFormat(CurrencyLocale[currency], {
    style: 'currency',
    currency,
  }).format(amount);

export const toCurrency = (amount: number, currency: Currency): string =>
  formatCurrency(numeral(amount).divide(100).value() ?? 0, currency);

export const fromCurrency = (amount: string): number => {
  try {
    return Number(
      numeral(amount || '0')
        .multiply(100)
        .format('0'),
    );
  } catch (e) {
    return 0;
  }
};
