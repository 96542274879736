import { LISTING_RECORDS_PER_PAGE } from 'pages/platform/constants';

import { IListingPagePropsObj, IPayoutFilterObj, IPayoutFilterObjError } from './types';

export const getInitialFilterState = (): IPayoutFilterObj => {
  return {
    amountChecked: false,
    amount: null,
    payoutIdChecked: false,
    payoutId: null,
    createdOnChecked: false,
    createdOn: null,
    availableOnChecked: false,
    availableOn: null,
    statusChecked: false,
    status: null,
  };
};

export const getInitialErrorState = (): IPayoutFilterObjError => {
  return {
    amountError: '',
    payoutIdError: '',
    createdOnError: '',
    availableOnError: '',
    statusError: '',
  };
};

export const getInitialListingPageState = (): IListingPagePropsObj => {
  return {
    page: 1,
    last: null,
    before: null,
    first: LISTING_RECORDS_PER_PAGE,
    after: null,
  };
};
