import { SettlementEntry } from 'api/merchant/dashboard/settlement.types';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import { MerchantDefaultRoutes } from 'pages/merchant/constants';
import { DashboardRoutesList } from 'pages/merchant/dashboard/constants';
import { PaymentRoutesList } from 'pages/merchant/dashboard/payment/constants';
import { PayoutRoutesList } from 'pages/merchant/dashboard/payout/constants';
import { TableStyleSettings, TableThemeSettings } from 'pages/platform/constants';
import DataTable, { SortOrder, TableColumn, createTheme } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { toCurrency } from 'utils/currency';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Link, Spinner } from '@limepayments/cosmic';

import EmptyComponent from './EmptyComponent';
import EmptyPayoutFilterComponent from './EmptyPayoutFilterComponent';
import ErrorComponent from './ErrorComponent';

const TransactionLink = ({ transactionId }: { transactionId: string }) => {
  const navigate = useNavigate();
  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <Link
      onClick={() =>
        navigate(
          `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
            DashboardRoutesList.PAYMENT_ROUTE
          }/${PaymentRoutesList.PAYMENT_DETAILS}?txnId=${transactionId}`,
        )
      }
      size="medium"
      target="_self"
      className="fw-medium fs-14"
    >
      {transactionId}
    </Link>
  );
};

const PayoutLink = ({ payoutId }: { payoutId: string }) => {
  const navigate = useNavigate();
  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <Link
      onClick={() =>
        navigate(
          `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
            DashboardRoutesList.PAYOUT_ROUTE
          }/${PayoutRoutesList.PAYOUT_DETAILS}?payoutId=${payoutId}`,
        )
      }
      size="medium"
      target="_self"
      className="fw-medium fs-14"
    >
      {payoutId}
    </Link>
  );
};

const columns: TableColumn<SettlementEntry>[] = [
  {
    name: 'Amount', // TODO: grossAmount, netAmount
    selector: (row: SettlementEntry) => toCurrency(row.netAmount, row.currency),
    width: '100px',
    right: true,
  },
  {
    name: '',
    selector: (row: SettlementEntry) => row.currency,
    width: '70px',
    style: {
      color: 'rgb(var(--lp-colors-neutral-400))',
    },
  },
  {
    name: 'Transaction type',
    selector: (row: SettlementEntry) => row.entryType ?? '',
    width: '150px',
  },
  {
    name: 'Transaction ID',
    cell: (row: SettlementEntry) => (row.transactionId ? <TransactionLink transactionId={row.transactionId} /> : null),
    width: '230px',
  },
  {
    name: 'Payout ID',
    cell: (row: SettlementEntry) => (row.payoutId ? <PayoutLink payoutId={row.payoutId} /> : null),
    width: '230px',
  },
  {
    name: 'Created on',
    selector: (row: SettlementEntry) => `${DateTime.fromISO(row.entryTimestamp).toFormat('dd/LL/yyyy, HH:mm')}`,
    sortable: true,
    sortField: 'createdAt',
    width: '145px',
  },
  {
    name: 'Available on',
    selector: (row: SettlementEntry) =>
      row.settlementDate ? `${DateTime.fromISO(row.settlementDate).toFormat('dd/LL/yyyy, HH:mm')}` : '',
    width: '145px',
  },
];

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  payoutList: Array<SettlementEntry>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  activePage: number;
  isFilterRequest: boolean;
  handleSort: (selectedColumn: TableColumn<SettlementEntry>, sortDirection: SortOrder) => void;
  isErrorRequest?: boolean;
  toggleFilter: () => void;
}

function PayoutBreakdownDataTable({
  payoutList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  activePage,
  handleSort,
  isFilterRequest,
  isErrorRequest,
  toggleFilter,
}: Props) {
  return (
    <s.PayoutsBreakdownTable>
      <DataTable
        pagination
        responsive
        columns={columns}
        data={payoutList}
        customStyles={TableStyleSettings}
        theme="polarized"
        className=""
        progressPending={fetchListLoader}
        progressComponent={
          <div className="transaction-empty">
            <div className="transaction-empty-inner">
              <Spinner variant="simple" isVisible label="Loading..." />
            </div>
          </div>
        }
        noDataComponent={
          isErrorRequest ? (
            <ErrorComponent />
          ) : isFilterRequest && payoutList.length < 1 ? (
            <EmptyPayoutFilterComponent toggleFilter={toggleFilter} />
          ) : (
            <EmptyComponent />
          )
        }
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={limit}
        paginationDefaultPage={activePage}
        onSort={handleSort}
        sortServer
        persistTableHead
        defaultSortFieldId={6}
        defaultSortAsc={false}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
      />
    </s.PayoutsBreakdownTable>
  );
}

export default PayoutBreakdownDataTable;
