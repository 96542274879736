import {
  BillingDetails,
  StandardMerchant,
  UpdateMerchantBillingSettingsRequest,
} from 'api/merchant/dashboard/merchants.types';

import { AddressModalForm } from '../../partials/address-modal/types';
import { NotificationEmailsModalForm } from '../../partials/notification-emails-modal/types';

type ToNotificationEmailsModalFormType = (billingDetails: BillingDetails) => NotificationEmailsModalForm;
type ToUpdateMerchantBillingSettingsRequestType = (
  modalForm: NotificationEmailsModalForm | AddressModalForm,
  standardMerchant: StandardMerchant,
) => UpdateMerchantBillingSettingsRequest;

export const toNotificationEmailsModalForm: ToNotificationEmailsModalFormType = (billingDetails: BillingDetails) => ({
  enabled: billingDetails.billingNotificationsEnabled,
  emailList: billingDetails.billingNotificationEmails || [],
  enteredEmail: '',
});

export const toUpdateMerchantBillingSettingsRequest: ToUpdateMerchantBillingSettingsRequestType = (
  modalForm: NotificationEmailsModalForm | AddressModalForm,
  standardMerchant: StandardMerchant,
) => ({
  UpdateMerchantBillingSettings: {
    billingNotificationEmails:
      'emailList' in modalForm
        ? modalForm.emailList
        : standardMerchant.StandardMerchant.billingDetails.billingNotificationEmails,
    billingNotificationsEnabled:
      'enabled' in modalForm
        ? modalForm.enabled
        : standardMerchant.StandardMerchant.billingDetails.billingNotificationsEnabled,
    billingAddress:
      'address' in modalForm
        ? modalForm.address
        : standardMerchant.StandardMerchant.billingDetails.billingAddress ||
          standardMerchant.StandardMerchant.businessProfile.address,
  },
});
