import { getMerchant, updateMerchant } from 'api/merchant/dashboard/merchants';
import { getStandardMerchantInitValues } from 'api/merchant/dashboard/merchants.init.values';
import { CustomField, StandardMerchant, UpdateOrderCustomFieldsRequest } from 'api/merchant/dashboard/merchants.types';
import { UNEXPECTED_ERROR } from 'config/constants';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import PayByLinkView from './partials/PayByLinkSettingView';
import { CustomFieldActions, CustomFieldModalForm, toCustomField } from './partials/types';

function PayByLinkPage() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updateErrorMessage, setUpdateErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [updateRequestLoading, setUpdateRequestLoading] = useState<boolean>(false);

  const [merchantResponse, setMerchantResponse] = useState<StandardMerchant>(() => getStandardMerchantInitValues());

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionCustomField, setActionCustomField] = useState<CustomField | null>(null);
  const [customFieldAction, setCustomFieldAction] = useState<CustomFieldActions | null>(null);

  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
  }));

  const updateMerchantCustomFields = useCallback(
    async (orderCustomFields: Array<CustomField>) => {
      const updateOrderCustomFieldsRequest: UpdateOrderCustomFieldsRequest = {
        UpdateOrderCustomFields: {
          fields: orderCustomFields,
        },
      };
      const response = await updateMerchant(merchantId, updateOrderCustomFieldsRequest);
      if ('StandardMerchant' in response) {
        setMerchantResponse(response);
      } else {
        throw new Error(UNEXPECTED_ERROR);
      }
    },
    [merchantId],
  );

  const handleSubmit = useCallback(
    async (
      values: CustomFieldModalForm,
      customFieldAction: CustomFieldActions | null,
      formObj: { resetForm: () => void },
    ) => {
      const customField: CustomField = toCustomField(values);
      let orderCustomFields = [...merchantResponse.StandardMerchant.orderCustomFields];

      switch (customFieldAction) {
        case CustomFieldActions.ADD:
          orderCustomFields.push(customField);
          break;
        case CustomFieldActions.EDIT:
          orderCustomFields = orderCustomFields.map((field) => {
            if (field.key === customField.key) {
              return customField;
            } else {
              return field;
            }
          });
          break;
        case CustomFieldActions.DELETE:
          orderCustomFields = orderCustomFields.filter((field) => field.key !== customField.key);
          break;
      }

      setUpdateErrorMessage('');
      setUpdateRequestLoading(true);

      return updateMerchantCustomFields(orderCustomFields)
        .then(() => {
          toast.success('Custom fields updated successfully');
          setActionCustomField(null);
          formObj.resetForm();
          setIsModalOpen(false);
        })
        .catch((error) => setUpdateErrorMessage(getErrorMessage(error)))
        .finally(() => setUpdateRequestLoading(false));
    },
    [merchantResponse.StandardMerchant.orderCustomFields, updateMerchantCustomFields],
  );

  const editDeleteHandler = useCallback(
    (key: string, action: CustomFieldActions) => {
      setActionCustomField(merchantResponse.StandardMerchant.orderCustomFields.filter((obj) => obj.key === key)[0]);
      setUpdateErrorMessage('');
      setCustomFieldAction(action);
      setIsModalOpen(true);
    },
    [merchantResponse.StandardMerchant.orderCustomFields],
  );

  const fetchMerchant = useCallback(async () => {
    try {
      setIsPageLoading(true);

      const response = await getMerchant(merchantId);
      if ('StandardMerchant' in response) {
        setMerchantResponse(response);
      } else {
        setErrorMessage('Merchant error');
      }
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsPageLoading(false);
    }
  }, [merchantId]);

  useEffect(() => {
    setErrorMessage('');
    fetchMerchant();
  }, [merchantId, fetchMerchant]);

  return (
    <MerchantDashboardLayout activeTab="settings" title="Pay by link">
      <PayByLinkView
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        customFieldsList={merchantResponse.StandardMerchant.orderCustomFields}
        actionCustomField={actionCustomField}
        setActionCustomField={setActionCustomField}
        customFieldAction={customFieldAction}
        setCustomFieldAction={setCustomFieldAction}
        onSubmit={handleSubmit}
        editDeleteHandler={editDeleteHandler}
        errorMessage={errorMessage}
        updateErrorMessage={updateErrorMessage}
        setUpdateErrorMessage={setUpdateErrorMessage}
        isPageLoading={isPageLoading}
        updateRequestLoading={updateRequestLoading}
      />
    </MerchantDashboardLayout>
  );
}

export default PayByLinkPage;
