import styled from '@emotion/styled';

export const FilterDropdown = styled.div`
  top: 40px;
  right: 0px;
  width: 100%;
  z-index: 3;
  position: absolute;
  background-color: #fff;
  display: flex;
  align-items: stretch;
  -ms-box-orient: vertical;
  box-orient: vertical;
  flex-direction: column;
  border-radius: var(--lp-space-xxxs);
  box-shadow: var(--lp-shadows-medium);

  &.w-328 {
    min-width: 328px;
    max-width: 328px;
  }
  &.w-449 {
    min-width: 449px;
    max-width: 449px;

    @media (max-width: 767px) {
      min-width: 328px;
      max-width: 328px;
    }
  }
  &.w-656 {
    min-width: 656px;
    max-width: 656px;

    @media (max-width: 767px) {
      min-width: 328px;
      max-width: 328px;
    }
  }

  .filter-body {
    flex-grow: 1;
    padding: 0 24px;
    -ms-box-orient: vertical;
    box-orient: vertical;
    flex-direction: column;

    fieldset {
      margin: 0 -24px;
      width: calc(100% + 48px);

      .checkbox-items {
        width: 100%;
        display: flex;
        -ms-box-orient: vertical;
        box-orient: vertical;
        flex-direction: column;
        padding: var(--lp-space-sm);
        border-bottom: 1px solid #f0f2f9;

        &:first-of-type,
        &:only-child {
          padding-top: 5px;
        }

        &:last-of-type,
        &:last-of-type,
        &:only-child {
          border-bottom: 0 none;
        }

        & > label {
          display: flex;
          align-items: center;
          width: 100%;
          margin: 0px !important;
        }

        &-details {
          display: flex;
          -ms-box-orient: vertical;
          box-orient: vertical;
          flex-direction: column;
          padding-top: var(--lp-space-sm);

          .w-24 {
            width: 24px;
            max-width: 24px;
          }
          .w-24,
          .w-40,
          .w-60px,
          .w-120px {
            flex-shrink: 0;
          }
          .w-40 {
            width: 40px;
            max-width: 40px;
            margin: 0 var(--lp-space-xs);
          }
          .w-60px {
            width: 62px;
            max-width: 62px;
            margin: 0 var(--lp-space-xs);
          }
          .w-140px {
            margin: 0;
            width: 140px;
            max-width: 140px;
          }
        }
      }
    }
    span.Mui-Error {
      display: inline-block;
      padding-top: var(--lp-space-xxxs);
      color: rgb(var(--lp-colors-error-600));
    }
  }

  .filter-header,
  .filter-footer,
  .filter-body {
    flex-shrink: 0;
  }

  .filter-footer,
  .filter-header {
    color: #191e33;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .filter-header {
    margin-bottom: 24px !important;
    border-bottom: 1px solid #f0f2f9;
    padding: var(--lp-space-sm) var(--lp-space-sm) var(--lp-space-xs);

    h2 {
      color: #191e33;
    }

    .button-close {
      min-width: inherit;
      margin: 0;
      padding: 0;
      width: 24px;
      height: 24px;

      svg {
        fill: #191e33;
      }
    }

    &.mb-0 {
      margin-bottom: 19px !important;
    }
  }

  .filter-footer {
    border-top: 1px solid #f0f2f9;
    padding: var(--lp-space-sm);

    button {
      min-width: inherit;
      max-width: 70px;
      margin: 0 !important;
    }
  }
`;
