import { useState } from 'react';

import { Button, Checkbox, CheckboxGroup, Heading, Icon } from '@limepayments/cosmic';

interface Props {
  toggleFilter: () => void;
}

function FilterComponent({ toggleFilter }: Props) {
  const [checkedAmount, setCheckedAmount] = useState(false);
  const [checkedTransactionType, setCheckedTransactionType] = useState(false);
  const [checkedTransactionID, setCheckedTransactionID] = useState(false);
  const [checkedPayoutId, setCheckedPayoutId] = useState(false);
  const [checkedCreatedOnDate, setCheckedCreatedOnDate] = useState(false);
  const [checkedAvailableOnDate, setCheckedAvailableOnDate] = useState(false);

  const handleAmountChange = () => {
    setCheckedAmount(!checkedAmount);
  };

  const handleTransactionTypeChange = () => {
    setCheckedTransactionType(!checkedTransactionType);
  };

  const handlePayoutChange = () => {
    setCheckedPayoutId(!checkedPayoutId);
  };

  const handleTransactionIDChange = () => {
    setCheckedTransactionID(!checkedTransactionID);
  };

  const handleCreatedOnDate = () => {
    setCheckedCreatedOnDate(!checkedCreatedOnDate);
  };

  const handleAvailableOnDate = () => {
    setCheckedAvailableOnDate(!checkedAvailableOnDate);
  };

  return (
    <div className="lp-flex lp-flex-column">
      <div className="filter-header">
        <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
          Filters
        </Heading>
        <Button
          type="button"
          onClick={() => toggleFilter()}
          size="small"
          variant="ghost"
          className="button-close m-0 p-0"
        >
          <Icon name="Cross" className="m-0 p-0" />
        </Button>
      </div>
      <div className="filter-body">
        <CheckboxGroup>
          <div className="checkbox-items">
            <Checkbox id="amount" label="Amount" variant="body-2" onChange={handleAmountChange} />
            {checkedAmount && (
              <div className="checkbox-items-details">
                <div className="lp-w-full mb-16">
                  <div className="selectdropdown">
                    <Icon name="ChevronDown" className="arrow-down" />
                    <select className="form-control" data-testid="payment-filter-date-select">
                      <option value="">please select</option>
                      <option value="is_equal_to">is equal to</option>
                      <option value="is_between">is between</option>
                      <option value="is_after">is greater than</option>
                      <option value="is_before">is less than</option>
                    </select>
                  </div>
                </div>
                <div className="lp-w-full lp-flex lp-align-center mb-0">
                  <div className="w-24">
                    <Icon name="Return" className="text-primary" />
                  </div>
                  <div className="w-60pxs ml-16px">
                    <input type="text" data-testid="payment-filter-date-value" className="form-control radius-0" />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="checkbox-items">
            <Checkbox
              id="transactiontype"
              label="Transaction type"
              variant="body-2"
              onChange={handleTransactionTypeChange}
            />
            {checkedTransactionType && (
              <div className="checkbox-items-details">
                <div className="lp-w-full small-checkboxes mb-0">
                  <CheckboxGroup>
                    <Checkbox id="transactiontype1" label="Payment" variant="body-3" />
                    <Checkbox id="transactiontype2" label="Refund" variant="body-3" />
                    <Checkbox id="transactiontype3" label="Service fee" variant="body-3" />
                    <Checkbox id="transactiontype4" label="Transfer" variant="body-3" />
                    <Checkbox id="transactiontype5" label="Fee credit" variant="body-3" />
                    <Checkbox id="transactiontype6" label="Transfer reversal" variant="body-3" />
                    <Checkbox id="transactiontype7" label="Dispute" variant="body-3" />
                    <Checkbox id="transactiontype8" label="Dispute fee" variant="body-3" />
                    <Checkbox id="transactiontype9" label="Dispute reversal" variant="body-3" />
                  </CheckboxGroup>
                </div>
              </div>
            )}
          </div>

          <div className="checkbox-items">
            <Checkbox id="transactionid" label="Transaction ID" variant="body-2" onChange={handleTransactionIDChange} />
            {checkedTransactionID && (
              <div className="checkbox-items-details">
                <div className="lp-w-full filter-search position-relative mb-0">
                  <input
                    type="text"
                    data-testid="payment-filter-order-id-input"
                    className="form-control border-bottom"
                    placeholder="Search"
                  />
                  <Button type="button" size="small" variant="ghost" className="button-search">
                    <Icon name="Search" className="m-0 p-0 text-primary" />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className="checkbox-items">
            <Checkbox id="payoutid" label="Payout ID" variant="body-2" onChange={handlePayoutChange} />
            {checkedPayoutId && (
              <div className="checkbox-items-details">
                <div className="lp-w-full filter-search position-relative mb-0">
                  <input
                    type="text"
                    data-testid="payment-filter-order-id-input"
                    className="form-control border-bottom"
                    placeholder="Search"
                  />
                  <Button type="button" size="small" variant="ghost" className="button-search">
                    <Icon name="Search" className="m-0 p-0 text-primary" />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className="checkbox-items">
            <Checkbox id="createdon" label="Created on" variant="body-2" onChange={handleCreatedOnDate} />
            {checkedCreatedOnDate && (
              <div className="checkbox-items-details">
                <div className="lp-w-full mb-16">
                  <div className="selectdropdown">
                    <Icon name="ChevronDown" className="arrow-down" />
                    <select className="form-control" data-testid="payment-filter-date-select">
                      <option value="">please select</option>
                      <option value="is_in_the_last">is in the last</option>
                      <option value="is_equal_to">is equal to</option>
                      <option value="is_between">is between</option>
                      <option value="is_after">is after</option>
                      <option value="is_before">is before</option>
                    </select>
                  </div>
                </div>
                <div className="lp-w-full lp-flex lp-align-center mb-0">
                  <div className="w-24">
                    <Icon name="Return" className="text-primary" />
                  </div>
                  <div className="w-40">
                    <input type="text" data-testid="payment-filter-date-value" className="form-control" />
                  </div>
                  <div className="selectdropdown">
                    <Icon name="ChevronDown" className="arrow-down" />
                    <select className="form-control" data-testid="payment-filter-date-filter-value">
                      <option value="">please select</option>
                      <option value="days">days</option>
                      <option value="month">months</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="checkbox-items">
            <Checkbox id="availableon" label="Available on" variant="body-2" onChange={handleAvailableOnDate} />
            {checkedAvailableOnDate && (
              <div className="checkbox-items-details">
                <div className="lp-w-full mb-16">
                  <div className="selectdropdown">
                    <Icon name="ChevronDown" className="arrow-down" />
                    <select className="form-control" data-testid="payment-filter-date-select">
                      <option value="">please select</option>
                      <option value="is_in_the_last">is in the last</option>
                      <option value="is_equal_to">is equal to</option>
                      <option value="is_between">is between</option>
                      <option value="is_after">is after</option>
                      <option value="is_before">is before</option>
                    </select>
                  </div>
                </div>
                <div className="lp-w-full lp-flex lp-align-center mb-0">
                  <div className="w-24">
                    <Icon name="Return" className="text-primary" />
                  </div>
                  <div className="w-40">
                    <input type="text" data-testid="payment-filter-date-value" className="form-control" />
                  </div>
                  <div className="selectdropdown">
                    <Icon name="ChevronDown" className="arrow-down" />
                    <select className="form-control" data-testid="payment-filter-date-filter-value">
                      <option value="">please select</option>
                      <option value="days">days</option>
                      <option value="month">months</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CheckboxGroup>
      </div>

      <div className="filter-footer">
        <Button className="lp-w-full" onClick={function noRefCheck() {}} size="medium" variant="reversed">
          Clear
        </Button>
        <Button className="lp-w-full" onClick={function noRefCheck() {}} size="medium" variant="primary">
          Save
        </Button>
      </div>
    </div>
  );
}

export default FilterComponent;
