/** @jsxImportSource @emotion/react */

import { AmountFilterInput } from 'api/graphql/generated/graphql';
import { fromCurrency } from 'utils/currency';

import { Icon } from '@limepayments/cosmic';

export type AmountFilterValue = {
  option: 'is_equal_to' | 'is_after' | 'is_before';
  value: string;
};

export const toAmountFilterInput = (amountFilter: AmountFilterValue): AmountFilterInput | null => {
  const { option, value } = amountFilter;

  const amount = fromCurrency(value);

  return option === 'is_equal_to'
    ? { eq: amount }
    : option === 'is_after'
    ? { gt: amount }
    : option === 'is_before'
    ? { lt: amount }
    : null;
};

export type AmountFilterProps = {
  value: AmountFilterValue | null;
  onChange(value: AmountFilterValue): void;
};

export function AmountFilter({ onChange, ...props }: AmountFilterProps) {
  const option = props.value?.option ?? 'is_equal_to';
  const value = props.value?.value ?? '';

  return (
    <>
      <div className="lp-w-full mb-16">
        <div className="selectdropdown">
          <Icon name="ChevronDown" className="arrow-down" />
          <select
            className="form-control"
            value={option}
            onChange={({ target: { value: option } }) =>
              onChange({ option: option as AmountFilterValue['option'], value })
            }
          >
            <option value="is_equal_to">is equal to</option>
            {/* TODO: range filters */}
            <option value="is_between" disabled>
              is between
            </option>
            <option value="is_after">is greater than</option>
            <option value="is_before">is less than</option>
          </select>
        </div>
      </div>
      <div className="lp-w-full lp-flex lp-align-center mb-0">
        <div className="w-24">
          <Icon name="Return" className="text-primary" />
        </div>
        <div className="lp-w-full ml-16px">
          <input
            type="text"
            className="form-control radius-0"
            value={value}
            onChange={({ target: { value } }) =>
              onChange({
                option,
                // TODO
                value: value.replace(/[^0-9.]+/g, ''),
              })
            }
          />
        </div>
      </div>
    </>
  );
}
