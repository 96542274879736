/** @jsxImportSource @emotion/react */

import { InstantFilterInput, LocalDateFilterInput } from 'api/graphql/generated/graphql';
import { DateTime } from 'luxon';

import { Icon } from '@limepayments/cosmic';

export type DateFilterValue = {
  option: 'is_in_the_last' | 'is_equal_to' | 'is_after' | 'is_before';
  unit: 'days' | 'months';
  value: string;
};

export const toInstantFilterInput = (dateFilter: DateFilterValue): InstantFilterInput | null => {
  const { option, unit, value } = dateFilter;

  if (option === 'is_in_the_last') {
    const now = DateTime.now();
    return {
      gte: now
        .minus({ [unit]: parseInt(value) })
        .startOf('day')
        .toISO(),
    };
  } else if (option === 'is_equal_to') {
    const date = DateTime.fromISO(value);
    return { gte: date.startOf('day').toISO(), lte: date.endOf('day').toISO() };
  } else if (option === 'is_after') {
    return { gt: DateTime.fromISO(value).endOf('day').toISO() };
  } else if (option === 'is_before') {
    return { lt: DateTime.fromISO(value).startOf('day').toISO() };
  }

  return null;
};

export const toLocalDateFilterInput = (dateFilter: DateFilterValue): LocalDateFilterInput | null => {
  const { option, unit, value } = dateFilter;

  if (option === 'is_in_the_last') {
    const now = DateTime.now();
    return {
      gte: now
        .minus({ [unit]: parseInt(value) })
        .startOf('day')
        .toISODate(),
    };
  } else if (option === 'is_equal_to') {
    return { eq: DateTime.fromISO(value).startOf('day').toISODate() };
  } else if (option === 'is_after') {
    return { gt: DateTime.fromISO(value).endOf('day').toISODate() };
  } else if (option === 'is_before') {
    return { lt: DateTime.fromISO(value).startOf('day').toISODate() };
  }

  return null;
};

export type DateFilterProps = {
  value: DateFilterValue | null;
  onChange(value: DateFilterValue): void;
};

export function DateFilter({ onChange, ...props }: DateFilterProps) {
  const option = props.value?.option ?? 'is_in_the_last';
  const unit = props.value?.unit ?? 'days';
  const value = props.value?.value ?? '';

  return (
    <>
      <div className="lp-w-full mb-16">
        <div className="selectdropdown">
          <Icon name="ChevronDown" className="arrow-down" />
          <select
            className="form-control"
            value={option}
            onChange={({ target: { value: option } }) =>
              onChange({ option: option as DateFilterValue['option'], unit, value })
            }
          >
            <option value="is_in_the_last">is in the last</option>
            <option value="is_equal_to">is equal to</option>
            {/* TODO: range filters */}
            <option value="is_between" disabled>
              is between
            </option>
            <option value="is_after">is after</option>
            <option value="is_before">is before</option>
          </select>
        </div>
      </div>
      <div className="lp-w-full lp-flex lp-align-center mb-0">
        <div className="w-24">
          <Icon name="Return" className="text-primary" />
        </div>
        {option === 'is_in_the_last' && (
          <>
            <div className="w-40">
              <input
                type="text"
                className="form-control"
                value={value}
                onChange={({ target: { value } }) => onChange({ option, unit, value })}
              />
            </div>
            <div className="selectdropdown">
              <Icon name="ChevronDown" className="arrow-down" />
              <select
                className="form-control"
                value={unit}
                onChange={({ target: { value: unit } }) =>
                  onChange({ option, unit: unit as DateFilterValue['unit'], value })
                }
              >
                <option value="days">days</option>
                <option value="months">months</option>
              </select>
            </div>
          </>
        )}
        {option !== 'is_in_the_last' && (
          <div className="lp-w-full ml-16px">
            <input
              // TODO: datepicker
              type="date"
              className="form-control"
              value={value}
              onChange={({ target: { value } }) => onChange({ option, unit, value })}
            />
          </div>
        )}
      </div>
    </>
  );
}
