import { updatePayoutReports } from 'api/merchant/dashboard/settings/payouts';
import { PayoutReportsAPIResponse } from 'api/merchant/dashboard/settings/payouts.types';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import NotificationEmailsModal from '../../partials/notification-emails-modal/NotificationEmailsModal';
import { NotificationEmailsModalForm } from '../../partials/notification-emails-modal/types';
import { toNotificationEmailsModalForm, toPaymentConfirmationAPIResponse } from './types';

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  payoutReports: PayoutReportsAPIResponse;
  refreshDataHandler: () => void;
}

function PayoutReportsModal({ isOpen, modalToggler, payoutReports, refreshDataHandler }: Props) {
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { apiBaseUri, merchantId } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
  }));

  const submitHandler = useCallback(
    async (values: NotificationEmailsModalForm, formObj: { resetForm: () => void }) => {
      try {
        setErrorMsg('');

        await updatePayoutReports(apiBaseUri, merchantId, toPaymentConfirmationAPIResponse(values, payoutReports));

        toast.success('Payout report settings updated successfully');

        formObj.resetForm();
        modalToggler(false);
        refreshDataHandler();
      } catch (error) {
        setErrorMsg(getErrorMessage(error));
      }
    },
    [apiBaseUri, merchantId, modalToggler, payoutReports, refreshDataHandler],
  );

  return (
    <NotificationEmailsModal
      heading={'Payout reports'}
      title={'Receive reports via email'}
      description={'After each payout is processed, a payout report will be sent to nominated emails.'}
      notificationEmailsData={toNotificationEmailsModalForm(payoutReports)}
      errorMessage={errorMsg}
      isModalOpen={isOpen}
      setIsModalOpen={modalToggler}
      submitHandler={submitHandler}
    />
  );
}

export default PayoutReportsModal;
