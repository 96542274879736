import { AmountFilter, DateFilter, FilterDropdown } from 'pages/common/filters';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setListingPagePropsObj, setPayoutFilterObj } from 'redux/merchant/slice/payoutSlice';

import { Button, Checkbox, CheckboxGroup, Heading, Icon, Text } from '@limepayments/cosmic';

import { getInitialErrorState, getInitialFilterState } from '../initialStates';
import { IPayoutFilterObj, IPayoutFilterObjError, hasFilterError } from '../types';

interface Props {
  modalToggler: () => void;
}

function FilterComponent({ modalToggler }: Props) {
  const { payoutFilterObj, listingPageProps } = useAppSelector((state) => ({
    payoutFilterObj: state.payout.payoutFilterObj,
    listingPageProps: state.payout.listingPageProps,
  }));

  const [filterObj, setFilterObj] = useState<IPayoutFilterObj>(payoutFilterObj);
  const [filterObjError, setFilterObjError] = useState<IPayoutFilterObjError>(getInitialErrorState());

  const dispatch = useAppDispatch();

  const filterChangeHandler = useCallback(
    (filterObj: Partial<IPayoutFilterObj>) =>
      setFilterObj((prevState) => ({
        ...prevState,
        ...filterObj,
      })),
    [],
  );

  const validate = useCallback(() => {
    const filterObjError = getInitialErrorState();
    const isRequiredError = 'This field is required';

    if (filterObj.amountChecked && !filterObj.amount) {
      filterObjError.amountError = isRequiredError;
    }
    if (filterObj.payoutIdChecked && !filterObj.payoutId) {
      filterObjError.payoutIdError = isRequiredError;
    }
    if (filterObj.createdOnChecked && !filterObj.createdOn) {
      filterObjError.createdOnError = isRequiredError;
    }
    if (filterObj.availableOnChecked && !filterObj.availableOn) {
      filterObjError.availableOnError = isRequiredError;
    }
    if (filterObj.statusChecked && !filterObj.status) {
      filterObjError.statusError = isRequiredError;
    }

    setFilterObjError(filterObjError);
    return filterObjError;
  }, [filterObj]);

  const filterSaveHandler = useCallback(async () => {
    if (hasFilterError(validate())) return;

    dispatch(setPayoutFilterObj({ ...filterObj }));
    dispatch(setListingPagePropsObj({ ...listingPageProps, page: 1 }));
    modalToggler();
  }, [dispatch, filterObj, validate, listingPageProps, modalToggler]);

  const clearFunction = useCallback(() => {
    setFilterObj(getInitialFilterState());
  }, []);

  return (
    <FilterDropdown className="w-328">
      <div className="lp-flex lp-flex-column">
        <div className="filter-header">
          <Heading alignment="left" tagName="h2" variant="xs" className="mb-0">
            Filters
          </Heading>
          <Button
            type="button"
            onClick={() => modalToggler()}
            size="small"
            variant="ghost"
            className="button-close m-0 p-0"
          >
            <Icon name="Cross" className="m-0 p-0" />
          </Button>
        </div>
        <div className="filter-body">
          <CheckboxGroup>
            <div className="checkbox-items">
              <Checkbox
                id="amount"
                label="Amount"
                variant="body-2"
                checked={filterObj.amountChecked}
                onChange={() =>
                  filterChangeHandler({
                    amountChecked: !filterObj.amountChecked,
                    amount: null,
                  })
                }
              />
              {filterObj.amountChecked && (
                <div className="checkbox-items-details">
                  <AmountFilter
                    value={filterObj.amount}
                    onChange={(amount) => filterChangeHandler({ amount: amount })}
                  />
                  {filterObjError.amountError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.amountError}
                    </Text>
                  )}
                </div>
              )}
            </div>
            {/* TODO: missing payoutId search query API */}
            {/* <div className="checkbox-items">
            <Checkbox
              id="payoutid"
              label="Payout ID"
              variant="body-2"
              checked={filterObj.payoutIdChecked}
              onChange={() =>
                filterChangeHandler({
                  payoutIdChecked: !filterObj.payoutIdChecked,
                  payoutId: null,
                })
              }
            />
            {filterObj.payoutIdChecked && (
              <div className="checkbox-items-details">
                <div className="lp-w-full filter-search position-relative mb-0">
                  <input
                    type="text"
                    data-testid="payment-filter-order-id-input"
                    className="form-control border-bottom"
                    placeholder="Search"
                  />
                  <Button type="button" size="small" variant="ghost" className="button-search">
                    <Icon name="Search" className="m-0 p-0 text-primary" />
                  </Button>
                </div>
                {filterObjError.payoutIdError && (
                  <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                    {filterObjError.payoutIdError}
                  </Text>
                )}
              </div>
            )}
          </div> */}
            <div className="checkbox-items">
              <Checkbox
                id="createdon"
                label="Created on"
                variant="body-2"
                checked={filterObj.createdOnChecked}
                onChange={() =>
                  filterChangeHandler({
                    createdOnChecked: !filterObj.createdOnChecked,
                    createdOn: null,
                  })
                }
              />
              {filterObj.createdOnChecked && (
                <div className="checkbox-items-details">
                  <DateFilter
                    value={filterObj.createdOn}
                    onChange={(createdOn) => filterChangeHandler({ createdOn })}
                  />
                  {filterObjError.createdOnError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.createdOnError}
                    </Text>
                  )}
                </div>
              )}
            </div>
            <div className="checkbox-items">
              <Checkbox
                id="availableon"
                label="Available on"
                variant="body-2"
                checked={filterObj.availableOnChecked}
                onChange={() =>
                  filterChangeHandler({
                    availableOnChecked: !filterObj.availableOnChecked,
                    availableOn: null,
                  })
                }
              />
              {filterObj.availableOnChecked && (
                <div className="checkbox-items-details">
                  <DateFilter
                    value={filterObj.availableOn}
                    onChange={(availableOn) => filterChangeHandler({ availableOn })}
                  />
                  {filterObjError.availableOnError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.availableOnError}
                    </Text>
                  )}
                </div>
              )}
            </div>
            {/* TODO: status filter */}
            {/* <div className="checkbox-items">
              <Checkbox
                id="status"
                label="Status"
                variant="body-2"
                checked={filterObj.statusChecked}
                onChange={() =>
                  filterChangeHandler({
                    statusChecked: !filterObj.statusChecked,
                    status: null,
                  })
                }
              />
              {filterObj.statusChecked && (
                <div className="checkbox-items-details">
                  <div className="lp-w-full small-checkboxes mb-0">
                    <CheckboxGroup>
                      <Checkbox id="statusId" label="Created" variant="body-3" />
                      <Checkbox id="statusId2" label="Cancelled" variant="body-3" />
                    </CheckboxGroup>
                  </div>
                  {filterObjError.statusError && (
                    <Text alignment="left" tagName="span" variant="caption" className="Mui-Error">
                      {filterObjError.statusError}
                    </Text>
                  )}
                </div>
              )}
            </div> */}
          </CheckboxGroup>
        </div>

        <div className="filter-footer">
          <Button className="lp-w-full" onClick={() => clearFunction()} size="medium" variant="reversed">
            Clear
          </Button>
          <Button className="lp-w-full" onClick={() => filterSaveHandler()} size="medium" variant="primary">
            Save
          </Button>
        </div>
      </div>
    </FilterDropdown>
  );
}

export default FilterComponent;
