import { SettlementEntry } from 'api/merchant/dashboard/settlement.types';
import DashboardRoutesList from 'pages/merchant/dashboard/constants';
import React, { useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';

import { Button, FlexContainer, Heading, Icon } from '@limepayments/cosmic';

import PayoutSidebar from '../../partials/PayoutSidebar';
import { IPayoutBreakdownFilterObj, IPayoutBreakdownFilterParentObj, IPayoutBreakdownFilterValuesObj } from '../types';
// Import Static UIs
import ExportComponent from './ExportComponent';
import FilterComponent from './FilterComponent';
import PayoutBreakdownDataTable from './PayoutBreakdownDataTable';

const getInitialFilterState = () => {
  return {
    dateValue: '',
    dateTypeCompareValue: '',
    dateTypeFilterValue: '',
    statusValue: '',
    dateRangeValue: '',
    dateSingleValue: null,
    saveClick: false,
    dateActive: false,
    statusFilterApply: false,
    merchantIdSelected: false,
    txnIdSelected: false,
    orderIdSelected: false,
    amountIdSelected: false,
    merchantId: '',
    orderId: '',
    txnId: '',
    amount: '',
    customerNameSelected: false,
    customerName: '',
    paymentOptionSelected: false,
    paymentOption: '',
  };
};

export interface Props {
  payoutList: Array<SettlementEntry>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  toggleFilter: () => void;
  filterRecordHandler: (obj: IPayoutBreakdownFilterValuesObj) => void;
  filterValuesObj: IPayoutBreakdownFilterParentObj;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<SettlementEntry>, sortDirection: SortOrder) => void;
  isFilterRequest?: boolean;
  isErrorRequest?: boolean;
}

function PayoutBreakdownPage({
  payoutList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  toggleFilter,
  filterRecordHandler,
  filterValuesObj,
  activePage,
  handleSort,
  isFilterRequest,
  isErrorRequest,
}: Props) {
  const [isExportOpened, setIsExportOpened] = useState(false);
  // filter section variables
  const [filterValueObj] = useState<IPayoutBreakdownFilterObj>(getInitialFilterState());

  const getFilterApplyCount = () => {
    let returnCount = 0;

    if (filterValueObj.dateActive && filterValueObj.saveClick) {
      returnCount++;
    }

    if (filterValueObj.statusFilterApply && filterValueObj.saveClick) {
      returnCount++;
    }

    if (filterValueObj.merchantIdSelected && filterValueObj.merchantId) {
      returnCount++;
    }

    if (filterValueObj.orderIdSelected && filterValueObj.orderId) {
      returnCount++;
    }

    if (filterValueObj.txnIdSelected && filterValueObj.txnId) {
      returnCount++;
    }

    if (filterValueObj.amountIdSelected && filterValueObj.amount) {
      returnCount++;
    }

    return returnCount;
  };

  function toggleExport() {
    setIsExportOpened((wasExportOpened) => !wasExportOpened);
  }

  return (
    <div className="content-wrap-inner lp-full m-0">
      <div className="lp-w-full lp-flex">
        <PayoutSidebar activeTab={DashboardRoutesList.PAYOUT_BREAKDOWN_ROUTE} />

        <div className="payment-content-area">
          <div className="lp-w-full lp-flex lp-justify-between lp-align-start sm:flex-col mb-16">
            <Heading alignment="left" tagName="h2" variant="sm">
              Payout breakdown
            </Heading>
            <div className="merchant-controls sm-mt-16 sm:lp-full sm:pr-0">
              <FlexContainer classNames="lp-flex m-0">
                <div className="lp-flex order-2 position-relative m-0 mb-0">
                  <Button
                    className="lp-w-full"
                    size="small"
                    variant="reversed"
                    onClick={() => toggleFilter()}
                    disabled={payoutList.length < 1 && getFilterApplyCount() === 0}
                  >
                    <Icon name="Filter" className="ml-0 mr-8" />
                    Filter ({getFilterApplyCount()})
                  </Button>

                  <Button disabled className="lp-w-full" size="small" variant="reversed" onClick={toggleExport}>
                    <Icon name="ExternalLink" className="ml-0 mr-8" />
                    Export
                  </Button>

                  {/* Filter Dropdown */}
                  {filterValuesObj.filterPopupOpen && (
                    <div className="filter-dropdown w-328">
                      <FilterComponent toggleFilter={toggleFilter} />
                    </div>
                  )}

                  {/* Export Dropdown */}
                  {isExportOpened && (
                    <div className="filter-dropdown w-656">
                      <ExportComponent onCloseExportClick={toggleExport} />
                    </div>
                  )}
                </div>
              </FlexContainer>
            </div>
          </div>

          <PayoutBreakdownDataTable
            payoutList={payoutList}
            fetchListLoader={fetchListLoader}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            limit={limit}
            activePage={activePage}
            handleSort={handleSort}
            isFilterRequest={isFilterRequest || getFilterApplyCount() > 0}
            isErrorRequest={isErrorRequest}
            toggleFilter={toggleFilter}
          />
        </div>
      </div>
    </div>
  );
}

export default PayoutBreakdownPage;
