import { CustomField } from 'api/merchant/dashboard/merchants.types';
import * as s from 'assets/styles/Platforms.styles';
import { Formik, FormikProps } from 'formik';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import styled from '@emotion/styled';
import { Button, Heading, Message, Modal, RadioGroup, Text, TextField } from '@limepayments/cosmic';

import { WarningText } from './CustomContent.styles';
import {
  CustomFielModalFormOnSubmit,
  CustomFieldActions,
  CustomFieldModalConfirmBtn,
  CustomFieldModalForm,
  CustomFieldModalTitle,
  RadioValues,
  toCustomFieldModalForm,
} from './types';

const RadioGroupWrapper = styled.div({
  '[role="radiogroup"]': {
    width: 'auto',
    'fieldset,label': {
      height: 'auto',
    },
  },
});

interface Props {
  isModalOpen: boolean;
  modalToggler: (val: boolean) => void;
  actionCustomField: CustomField | null;
  customFieldAction: CustomFieldActions | null;
  existingKeys: string[];
  onSubmitClick: CustomFielModalFormOnSubmit;
  updateRequestLoading: boolean;
  updateErrorMessage: string;
}

const formInitValues: CustomFieldModalForm = {
  key: '',
  label: '',
  required: RadioValues.REQUIRED,
  visibleForCustomer: RadioValues.VISIBLE,
};

function CustomFieldModal({
  isModalOpen,
  modalToggler,
  actionCustomField,
  customFieldAction,
  existingKeys,
  onSubmitClick,
  updateRequestLoading,
  updateErrorMessage,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<CustomFieldModalForm>>(null);
  const [initialValues, setInitialValues] = useState<CustomFieldModalForm>(formInitValues);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        label: Yup.string()
          .trim()
          .required('This field is required')
          .when('key', (key, schema) =>
            schema.notOneOf(
              existingKeys.filter((eKey) => eKey !== key),
              'This label already exists',
            ),
          ),
      }),
    [existingKeys],
  );

  useEffect(() => {
    if (updateErrorMessage.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [updateErrorMessage]);

  useLayoutEffect(() => {
    if (actionCustomField) {
      setInitialValues(toCustomFieldModalForm(actionCustomField));
    } else {
      setInitialValues(formInitValues);
    }
  }, [isModalOpen, actionCustomField]);

  return (
    <Modal isOpen={isModalOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-24 lp-justify-left lp-flex">
                {customFieldAction && CustomFieldModalTitle[customFieldAction]}
              </Heading>
            </div>
          </div>

          <Formik<CustomFieldModalForm>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              onSubmitClick(values, customFieldAction, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, handleChange, handleBlur, setFieldValue }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content sm-p-0 sm-mt-24">
                  {updateErrorMessage.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{updateErrorMessage}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}
                  {customFieldAction !== CustomFieldActions.DELETE && (
                    <div>
                      <div className="lp-w-full w-200  mb-24">
                        <TextField
                          label="Item label"
                          containerId="standard"
                          id="label"
                          name="label"
                          type="input"
                          value={values.label}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.label && touched.label ? errors.label : ''}
                        ></TextField>
                      </div>
                      <div className="lp-w-full mb-24">
                        <Text variant="caption" className="text-dark-50">
                          This section allows you to determine how the customer will interact with this additional
                          field.
                        </Text>
                      </div>
                      <RadioGroupWrapper className="mb-24">
                        <RadioGroup
                          defaultValue={values.required}
                          description="This option allows you to select whether a field will be mandatory or optional for the payment being made."
                          header=""
                          name="required"
                          onChange={(value) => setFieldValue('required', value)}
                          orientation="vertical"
                          radios={[
                            {
                              text: 'Required field - This fields must be completed before order can be send to customer',
                              value: RadioValues.REQUIRED,
                            },
                            {
                              text: 'Optional field',
                              value: RadioValues.OPTIONAL,
                            },
                          ]}
                          errorMessage={errors.required && touched.required ? errors.required : ''}
                        />
                      </RadioGroupWrapper>

                      <RadioGroupWrapper className="mb-24">
                        <RadioGroup
                          defaultValue={values.visibleForCustomer}
                          description="This option allows you to determine if this field will be visible for the customer to review or is hidden."
                          header=""
                          name="visibleForCustomer"
                          onChange={(value) => setFieldValue('visibleForCustomer', value)}
                          orientation="vertical"
                          radios={[
                            {
                              text: 'Visible - Customers will see this field on their invoice',
                              value: RadioValues.VISIBLE,
                            },
                            {
                              text: 'Hidden - Used for internal reporting purposes only ',
                              value: RadioValues.HIDDEN,
                            },
                          ]}
                          errorMessage={
                            errors.visibleForCustomer && touched.visibleForCustomer ? errors.visibleForCustomer : ''
                          }
                        />
                      </RadioGroupWrapper>
                    </div>
                  )}
                  {customFieldAction === CustomFieldActions.DELETE && (
                    <WarningText data-testid="modal-delete-warning">
                      Are you sure you want to delete the field{' '}
                      <Text isEmphasised={true} tagName="span">
                        {values.label}
                      </Text>
                      ?
                    </WarningText>
                  )}
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    onClick={() => modalToggler(false)}
                    disabled={updateRequestLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    type="submit"
                    disabled={updateRequestLoading}
                    isLoading={updateRequestLoading}
                  >
                    {customFieldAction && CustomFieldModalConfirmBtn[customFieldAction]}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default CustomFieldModal;
