import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  address: Yup.object().shape({
    city: Yup.string()
      .when('country', {
        is: 'NZ',
        then: Yup.string().nullable().trim().required('This field is required'),
      })
      .nullable(),
    state: Yup.string()
      .when('country', {
        is: 'AU',
        then: Yup.string().nullable().trim().required('This field is required'),
      })
      .nullable(),
    postalCode: Yup.string()
      .trim()
      .required('This field is required')
      .matches(/^[0-9]+$/, 'Only digits are allowed for this field'),
    streetNumber: Yup.string().trim().required('This field is required'),
    streetName: Yup.string().trim().required('This field is required'),
  }),
});
