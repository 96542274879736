/** @jsxImportSource @emotion/react */

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useEffect, useMemo, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from '@mui/material/utils';

export type SearchFilterOption = {
  value: string;
  label: string;
};

export type SearchFilterProps = {
  value: SearchFilterOption | null;
  options: SearchFilterOption[];
  placeholder: string;
  loading?: boolean;
  error?: string;
  onChange(value: SearchFilterOption | null): void;
  onInputChange(value: string): void;
};

export function SearchFilter({
  value,
  options,
  placeholder,
  loading,
  error,
  onChange,
  onInputChange,
}: SearchFilterProps) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useMemo(() => debounce(onInputChange, 400), [onInputChange]);

  useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue, handleInputChange]);

  return (
    <div
      css={{
        '.MuiInput-root': {
          fontFamily: 'var(--lp-fonts-body-3)',
          fontSize: 'var(--lp-font-size-body-3)',
          color: 'rgb(var(--lp-colors-neutral-600))',
          ':before': {
            borderBottomColor: 'rgb(var(--lp-colors-neutral-200)) !important',
          },
          ':after': {
            borderBottomColor: 'rgb(var(--lp-colors-primary))',
          },
        },
        '.MuiPaper-root': {
          fontFamily: 'var(--lp-fonts-body-3)',
          fontSize: 'var(--lp-font-size-body-3)',
          color: 'rgb(var(--lp-colors-neutral-600))',
        },
        '.MuiInput-input::placeholder': {
          color: 'rgb(var(--lp-colors-neutral-600))',
          opacity: 1,
        },
      }}
    >
      <Autocomplete
        value={value}
        inputValue={inputValue}
        options={options}
        loading={loading}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField {...params} variant="standard" placeholder={placeholder} helperText={error} error={!!error} />
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.label, inputValue, { insideWords: true });
          const parts = parse(option.label, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
        disablePortal
      />
    </div>
  );
}
