import { addressToString } from 'api/fragments';
import { MerchantGeneralSettingsResponse, StandardMerchant } from 'api/merchant/dashboard/merchants.types';
import { FormikHelpers } from 'formik';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment } from 'react';

import { Button, Heading, Icon, LabelledValue, Spinner, Text } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import SettingRoutesList from '../../constants';
// Import Static UIs
import SettingsSidebar from '../../partials/SettingsSidebar';
import AddressModal from '../../partials/address-modal/AddressModal';
import { AddressModalForm } from '../../partials/address-modal/types';
import NotificationEmailsModal from '../../partials/notification-emails-modal/NotificationEmailsModal';
import { NotificationEmailsModalForm } from '../../partials/notification-emails-modal/types';
import { toNotificationEmailsModalForm } from './types';

export interface Props {
  merchantDetails: StandardMerchant;
  generalSettings: MerchantGeneralSettingsResponse;
  errorMessage: string;
  updateEmailNotificationsErrorMessage: string;
  updateAddressErrorMessage: string;
  isPageLoading: boolean;
  isBillingDetailsFetchLoading: boolean;
  isNotificationEmailsModalOpen: boolean;
  isAddressModalOpen: boolean;
  setIsNotificationEmailsModalOpen: (value: boolean) => void;
  notificationEmailsSubmitHandler: (
    values: NotificationEmailsModalForm,
    formObj: FormikHelpers<NotificationEmailsModalForm>,
  ) => Promise<void>;
  setIsAddressModalOpen: (value: boolean) => void;
  addressSubmitHandler: (values: AddressModalForm, formObj: FormikHelpers<AddressModalForm>) => Promise<void>;
}

function BillingSettingView({
  merchantDetails,
  generalSettings,
  errorMessage,
  updateEmailNotificationsErrorMessage,
  updateAddressErrorMessage,
  isPageLoading,
  isBillingDetailsFetchLoading,
  isNotificationEmailsModalOpen,
  isAddressModalOpen,
  setIsNotificationEmailsModalOpen,
  notificationEmailsSubmitHandler,
  setIsAddressModalOpen,
  addressSubmitHandler,
}: Props) {
  const billingDetails = merchantDetails.StandardMerchant?.billingDetails;
  const billingAddresss = billingDetails.billingAddress
    ? billingDetails.billingAddress
    : merchantDetails.StandardMerchant?.businessProfile.address;

  return (
    <Fragment>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab={SettingRoutesList.SETTINGS_BILLING} />
          </div>

          <div className="payment-content-area">
            {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

            {isPageLoading && (
              <div className="spinner-wrapper">
                <Spinner variant="simple" isVisible label="Loading..." />
              </div>
            )}

            {!isPageLoading && !errorMessage.length && (
              <Fragment>
                <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
                  <Heading alignment="left" tagName="h2" variant="sm">
                    Billing
                  </Heading>
                </div>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full mb-32">
                    <div>
                      <Text variant="body-3" className="mt-4">
                        This is the billing information for fees and charges related to your services.
                      </Text>
                    </div>
                  </div>
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <LabelledValue label="Address" value={addressToString(billingAddresss)} variant="vertical" />
                    </div>
                    <div className="ml-auto">
                      {!isBillingDetailsFetchLoading && (
                        <Button
                          size="small"
                          variant="reversed"
                          onClick={() => setIsAddressModalOpen(!isAddressModalOpen)}
                        >
                          Edit
                          <Icon name="Edit" />
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <LabelledValue
                        label="Receive notifications via email"
                        value={billingDetails.billingNotificationsEnabled ? 'Enabled' : 'Disabled'}
                        variant="vertical"
                      />
                    </div>
                    <div className="ml-auto">
                      {!isBillingDetailsFetchLoading && (
                        <Button
                          size="small"
                          variant="reversed"
                          onClick={() => setIsNotificationEmailsModalOpen(!isNotificationEmailsModalOpen)}
                        >
                          Edit
                          <Icon name="Edit" />
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <LabelledValue
                        label="Email"
                        value={billingDetails.billingNotificationEmails?.join(', ') || ''}
                        variant="vertical"
                      />
                    </div>
                  </div>
                </s.MerchantsData>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <LabelledValue
                        label={`${generalSettings.settlementType} settlement`}
                        value={
                          generalSettings.settlementType === 'Net'
                            ? 'All fees and charges will be automatically deducted from each transaction and settled into your nominated bank account'
                            : 'All fees and charges will be invoiced to your account on a monthly basis'
                        }
                        variant="vertical"
                      />
                    </div>
                  </div>
                </s.MerchantsData>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <NotificationEmailsModal
        heading={'Billing notifications'}
        title={'Receive notifications via email'}
        notificationEmailsData={toNotificationEmailsModalForm(billingDetails)}
        errorMessage={updateEmailNotificationsErrorMessage}
        isModalOpen={isNotificationEmailsModalOpen}
        setIsModalOpen={setIsNotificationEmailsModalOpen}
        submitHandler={notificationEmailsSubmitHandler}
      />
      <AddressModal
        title={'Billing address'}
        address={billingAddresss}
        errorMessage={updateAddressErrorMessage}
        isModalOpen={isAddressModalOpen}
        setIsModalOpen={setIsAddressModalOpen}
        submitHandler={addressSubmitHandler}
      />
    </Fragment>
  );
}

export default BillingSettingView;
