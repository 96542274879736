import {
  getInitialFilterState,
  getInitialListingPageState,
} from 'pages/merchant/dashboard/payment/listing/initialStates';
import { IListingPagePropsObj, IPaymentFilterObj } from 'pages/merchant/dashboard/payment/listing/types';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IConfig {
  paymentFilterObj: IPaymentFilterObj;
  listingPageProps: IListingPagePropsObj;
}

const initialState: IConfig = {
  paymentFilterObj: getInitialFilterState(),
  listingPageProps: getInitialListingPageState(),
};

export const paymentSlice = createSlice({
  name: 'paymentConfig',
  initialState,
  reducers: {
    setPaymentFilterObj: (state, action: PayloadAction<IPaymentFilterObj>) => {
      state.paymentFilterObj = action.payload;
    },
    setListingPagePropsObj: (state, action: PayloadAction<IListingPagePropsObj>) => {
      state.listingPageProps = action.payload;
    },
  },
});

export const { setPaymentFilterObj, setListingPagePropsObj } = paymentSlice.actions;

export default paymentSlice.reducer;
