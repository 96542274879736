/** @jsxImportSource @emotion/react */

import { searchOrdersQueryDocument } from 'api/graphql';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'redux/merchant/hooks'; // TODO: merchantId prop
import { useQuery } from 'urql';

import { SearchFilter, SearchFilterOption, SearchFilterProps } from './SearchFilter';

export type OrderFilterProps = {
  value: SearchFilterOption | null;
  onChange(value: SearchFilterOption | null): void;
};

export function OrderFilter({ value, onChange, ...props }: OrderFilterProps) {
  const [query, setQuery] = useState('');

  // TODO: merchantId prop
  const { merchantId } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
  }));

  const pause = query.length < 3;

  const [{ fetching, data, error }] = useQuery({
    query: searchOrdersQueryDocument,
    variables: { merchantIds: merchantId, query },
    pause,
  });

  const options = useMemo<SearchFilterProps['options']>(() => {
    if (pause || !data?.search.orders) return [];

    return data.search.orders.map(({ orderId, referenceOrderId }) => ({
      value: orderId,
      label: referenceOrderId,
    }));
  }, [data?.search.orders, pause]);

  return (
    <SearchFilter
      value={value}
      options={options}
      placeholder="Search order ID"
      loading={fetching}
      error={error?.message}
      onChange={onChange}
      onInputChange={setQuery}
    />
  );
}
