import { configureStore } from '@reduxjs/toolkit';

import configSlice from './slice/configSlice';
import merchantOnboardSlice from './slice/merchantOnboardSlice';
import merchantSlice from './slice/merchantSlice';
import paymentSlice from './slice/paymentSlice';
import payoutSlice from './slice/payoutSlice';

const store = configureStore({
  reducer: {
    config: configSlice,
    payment: paymentSlice,
    payout: payoutSlice,
    onboarding: merchantOnboardSlice,
    merchant: merchantSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
