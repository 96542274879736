import { PayoutReportsAPIResponse } from 'api/merchant/dashboard/settings/payouts.types';

import { NotificationEmailsModalForm } from '../../partials/notification-emails-modal/types';

type ToNotificationEmailsModalFormType = (
  payoutReportsAPIResponse: PayoutReportsAPIResponse,
) => NotificationEmailsModalForm;
type ToPaymentConfirmationAPIResponseType = (
  notificationEmailsModalForm: NotificationEmailsModalForm,
  payoutReportsAPIResponse: PayoutReportsAPIResponse,
) => PayoutReportsAPIResponse;

export const toNotificationEmailsModalForm: ToNotificationEmailsModalFormType = (
  payoutReportsAPIResponse: PayoutReportsAPIResponse,
) => ({
  enabled: payoutReportsAPIResponse.notificationsEnabled || false,
  emailList: payoutReportsAPIResponse.notificationEmails || [],
  enteredEmail: '',
});

export const toPaymentConfirmationAPIResponse: ToPaymentConfirmationAPIResponseType = (
  notificationEmailsModalForm: NotificationEmailsModalForm,
  payoutReportsAPIResponse: PayoutReportsAPIResponse,
) => ({
  ...payoutReportsAPIResponse,
  notificationsEnabled: notificationEmailsModalForm.enabled,
  notificationEmails: notificationEmailsModalForm.emailList,
});
