import { Address } from 'api/fragments';

export interface AddressModalForm {
  address: Address;
}

export const initAddress: AddressModalForm = {
  address: {
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    suburb: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'AU',
  },
};
