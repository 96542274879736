import { updatePaymentConfirmation } from 'api/merchant/dashboard/settings/payment';
import { PaymentConfirmationAPIResponse } from 'api/merchant/dashboard/settings/payment.types';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import NotificationEmailsModal from '../../partials/notification-emails-modal/NotificationEmailsModal';
import { NotificationEmailsModalForm } from '../../partials/notification-emails-modal/types';
import { toNotificationEmailsModalForm, toPaymentConfirmationAPIResponse } from './types';

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  paymentConfirmationObj: PaymentConfirmationAPIResponse;
  refreshDataHandler: () => void;
}

function PaymentConfirmationsModal({ isOpen, modalToggler, paymentConfirmationObj, refreshDataHandler }: Props) {
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { apiBaseUri, merchantId } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
  }));

  const submitHandler = useCallback(
    async (values: NotificationEmailsModalForm, formObj: { resetForm: () => void }) => {
      try {
        setErrorMsg('');

        await updatePaymentConfirmation(
          apiBaseUri,
          merchantId,
          toPaymentConfirmationAPIResponse(values, paymentConfirmationObj),
        );

        toast.success('Payment confirmation updated successfully');

        formObj.resetForm();
        modalToggler(false);
        refreshDataHandler();
      } catch (error) {
        setErrorMsg(getErrorMessage(error));
      }
    },
    [apiBaseUri, merchantId, modalToggler, paymentConfirmationObj, refreshDataHandler],
  );

  return (
    <NotificationEmailsModal
      heading={'Payment confirmations'}
      title={'Receive notifications via email'}
      description={'After a new transaction is processed, a payment notification will be sent to nominated emails.'}
      notificationEmailsData={toNotificationEmailsModalForm(paymentConfirmationObj)}
      errorMessage={errorMsg}
      isModalOpen={isOpen}
      setIsModalOpen={modalToggler}
      submitHandler={submitHandler}
    />
  );
}

export default PaymentConfirmationsModal;
