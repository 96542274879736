import { TransactionOrderField } from 'api/graphql/generated/graphql';
import { LISTING_RECORDS_PER_PAGE } from 'pages/platform/constants';

import { IListingPagePropsObj, IPaymentFilterObj, IPaymentFilterObjError } from './types';

export const getInitialFilterState = (): IPaymentFilterObj => {
  return {
    amountChecked: false,
    amount: null,
    statusChecked: false,
    status: null,
    customerChecked: false,
    customer: null,
    paymentOptionChecked: false,
    paymentOption: null,
    orderIdChecked: false,
    orderId: null,
    transactionIdChecked: false,
    transactionId: null,
    createdOnChecked: false,
    createdOn: null,
    availableOnChecked: false,
    availableOn: null,
  };
};

export const getInitialErrorState = (): IPaymentFilterObjError => {
  return {
    amountError: '',
    statusError: '',
    customerError: '',
    paymentOptionError: '',
    orderIdError: '',
    transactionIdError: '',
    createdOnError: '',
    availableOnError: '',
  };
};

export const getInitialListingPageState = (): IListingPagePropsObj => {
  return {
    page: 1,
    last: null,
    before: null,
    first: LISTING_RECORDS_PER_PAGE,
    after: null,
    orderBy: {
      ascending: false,
      field: TransactionOrderField.CreatedAt,
    },
  };
};
